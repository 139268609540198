<script setup>
import { useNotifsTailed } from '@/composables/use-notifs';
import { useRuntimeConfig } from '#imports';
import { onMounted, ref, computed } from 'vue';
import { useAuth } from '@/composables/use-auth';
import { usePersonalDetails } from '@/composables/use-personal-details';
import { useRouter } from 'vue-router';
import { useToast } from '@/composables/use-ui';
import GlobalToast from '@/components/commons/GlobalToast.vue';

const router = useRouter();
const { showToast } = useToast();

useNotifsTailed({
  onNewNotif: (notif) => {
    showToast(notif.title, { type: 'alert-info' });
  },
});

const { currentUser, signout } = useAuth();
const { getItem: getPersonalDetails } = usePersonalDetails();

const personalDetails = ref({});
const email = computed(() => currentUser.value?.email);
const picURL = computed(() => personalDetails.value?.picURL);

onMounted(async () => {
  if (!currentUser.value) {
    signout();
    router.push({ name: 'login' });
  }
  
  personalDetails.value = await getPersonalDetails(currentUser.value?.uid);
});

async function onSignOut () {
  await signout();
  router.push({ name: 'login' });
}

const appVersion = useRuntimeConfig().public?.appVersion;

const drawer = ref(false);
</script>

<template>
  <div data-theme="parmazip">
    <div class="navbar justify-between border-b bg-primary">
      <div>
        <label for="nav-drawer" class="btn btn-sm btn-primary btn-outline btn-square mr-2 drawer-button lg:hidden">
          <i class="las la-bars text-2xl text-white"/>
        </label>
        <div class="flex items-center gap-2">
          <nuxt-link href="/" class="flex-1">
            <img src="@/assets/images/parmazip-logo-white.png" class="h-[65px]" alt="Parmazip Logo">
          </nuxt-link>
          <h1 class="text-2xl text-white font-general">Pharmacy</h1>
        </div>
      </div>
      <div class="flex-none">
        <div class="dropdown dropdown-end">
          <div tabindex="0" role="button" class="btn btn-ghost btn-circle avatar">
            <div class="w-10 rounded-full">
              <img
                :alt="email"
                :src="picURL"
              >
            </div>
          </div>
          <ul
            tabindex="0"
            class="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
            <li>
              <nuxt-link to="/dashboard/pharmacy-profile">
                Profile
              </nuxt-link>
            </li>
            <li>
              <nuxt-link @click="onSignOut">
                Log Out
              </nuxt-link>
            </li>
            <li v-if="appVersion">
              <span class="text-xs mt-2">App Version: {{ appVersion }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="drawer lg:drawer-open">
      <input v-model="drawer" id="nav-drawer" type="checkbox" class="drawer-toggle" >
      <div class="drawer-content flex flex-col items-start justify-start p-6">
        <slot/>
      </div>
      <div class="drawer-side">
        <label for="nav-drawer" aria-label="close sidebar" class="drawer-overlay"/>
        <ul class="menu bg-slate-100 text-base-content min-h-full w-80 p-4">
          <!-- Sidebar content here -->
          <li>
            <nuxt-link to="/dashboard/pharmacy" @click="drawer = false;">
              <i class="las la-clinic-medical text-2xl"/>
              My Facility
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/pharmacy-pharmacists" @click="drawer = false;">
              <i class="las la-users text-2xl"/>
              Pharmacists
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/pharmacy-transactions" @click="drawer = false;">
              <i class="las la-money-check-alt text-2xl"/>
              Transactions
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/pharmacy-prescription-orders" @click="drawer = false;">
              <i class="las la-prescription-bottle-alt text-2xl"/>
              Prescription Orders
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/pharmacy-profile" @click="drawer = false;">
              <i class="las la-user-cog text-2xl"/>
              Account
            </nuxt-link>
          </li>
          <li>
            <nuxt-link to="/dashboard/pharmacy-notifs" @click="drawer = false;">
              <i class="las la-bell text-2xl"/>
              Notifications
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <GlobalToast/>
</template>
